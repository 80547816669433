<template>
  <div class="header-wrapper">
    <header class="header container">
      <!-- Header TOP -->
      <header-top></header-top>

      <!-- Header MENU -->
      <header-menu></header-menu>
    </header>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  components: {
    HeaderTop: () => import("@/components/common/header/HeaderTop.vue"),
    HeaderMenu: () => import("@/components/common/header/HeaderMenu.vue"),
  },
  mounted() {
    console.log("😎");
  },
};
</script>
